import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import './Image.css'

class Image extends React.Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  imageSizes = [
    '320',
    '450',
    '640',
    '750',
    '800',
    '900',
    '1000',
    '1200',
    '1500',
    '1600',
    '2000'
  ] // image sizes used for image source sets

  render() {
    let {
      background,
      resolutions = '1000x',
      className = '',
      src,
      title = '',
      alt = '',
      lazy = true
    } = this.props;

    return (
      <Fragment>
        {background && ( <img className='bg__img' src={src} ref={this.ref}/>)}
        {!background && (
          <img
            className={`${className}`}
            src={src}
            title={title}
            alt={alt}
          />
        )}
      </Fragment>
    )
  }
}

Image.propTypes = {
  alt: PropTypes.string.isRequired
}

export default Image
